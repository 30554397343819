import { useEffect } from "react";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Breadcrumb from '../../components/Breadcrumb';
import ScrollToTop from '../../components/ScrollTop';
import FaqMain from "./FaqMain";


const Faq = () => {

    useEffect(() => {
        document.title = "Thunder Player | FAQ";
    }, []);
    return (
        <>
            <Header
                parentMenu='page'
            />

            <div className="react-wrapper">
                <div className="react-wrapper-inner">

                    <Breadcrumb pageTitle="HAVE A QUESTION?" pageDescription="Frequently Asked Questions" />

                    <FaqMain />

                    <ScrollToTop />
                </div>
            </div>

            <Footer />

        </>
    );
}

export default Faq;