import React from "react";
import ResellerForm from "./ResellerForm"

import resellerImg from '../../assets/images/reseller.svg'

const BecomeResellerMain = () => {


    return (
        <>
            <div id="react-become-reseller" className="react-contact-page pt---100">
                <div className="container">
                    <div className="row pb---96">
                        <div className="col-lg-4 pt---10">
                            <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                                <img src={resellerImg} alt="Reseller" />
                                {/* <img className="react__shape__ab" src={shapeImg} alt="Shape Image" /> */}
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <ResellerForm />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BecomeResellerMain;