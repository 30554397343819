import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { logout as logoutAction } from '../../redux/auth/actions';
import Preloader from "../../components/Preloader";

const Logout = () => {
    const dispatch = useDispatch();
    function asyncLogout() {
        return dispatch(logoutAction());
    }
    useEffect(() => {
        asyncLogout();
    }, []);
    return <Preloader />;
};
export default Logout;
