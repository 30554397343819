
import { toast } from "react-toastify";
import { decrypt } from '../utils/encrypt';
import axiosInstance from './axiosConfig';

const errorHandler = async (error) => {

    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        let { status, data } = error.response;
        if (data) {
            data = await decrypt(data);
        }

        if (data && data.jwtExpired) { // logout
            window.location.href = '/logout';
        }

        let message = 'An error occurred';

        if (status === 403 || status === 503) {
            window.location.href = '/logout';
            message = 'Access is forbidden. Please check your permissions.';
        } else if (status === 500) {
            message = 'Internal server error. Please try again later.';
        } else {
            message = data.message || message;
        }

        toast.error(message, { autoClose: 8000, });
        console.error(`Error ${status}:`, message);
    } else if (error.request) {
        // The request was made but no response was received
        toast.error("No response received from the server. Please check your network connection.", { autoClose: 8000, });
        console.error('Error: No response received from the server.');
    } else {
        // Something happened in setting up the request that triggered an Error
        toast.error("An error occurred while setting up the request.", { autoClose: 8000, });
        console.error('Error:', error.message);
    }
}
const request = {
    list: async ({ entity, options = {} }) => {
        try {
            let query = '?';
            for (var key in options) {
                query += key + '=' + options[key] + '&';
            }
            query = query.slice(0, -1);

            const response = await axiosInstance.get(entity + query);
            if (!response.success) {
                toast.error(response.message, { autoClose: 8000, });
                return [];
            }
            return response.data;
        } catch (error) {
            errorHandler(error)
            return [];
        }
    },
    create: async ({ entity, jsonData }) => {
        console.log('🚀 Create Request 🚀 ~ file: request.js ~ line 19 ~ create: ~ jsonData', jsonData);

        try {
            const response = await axiosInstance.post(entity, jsonData);
            if (!response.success) {
                toast.error(response.message, { autoClose: 8000, });
                return false;
            } else {
                toast.success(response.message, { autoClose: 8000, });
                return response.data;
            }
        } catch (error) {
            errorHandler(error)
            return;
        }
    },
    update: async ({ entity, id, jsonData }) => {
        console.log('🚀 ~ file: request.js ~ line 34 ~ update: ~ id', id);
        console.log('🚀 Update Request 🚀 ~ file: request.js ~ line 42 ~ update: ~ jsonData', jsonData);

        try {
            const response = await axiosInstance.put(entity + '/' + id, jsonData);
            if (!response.success) {
                toast.error(response.message, { autoClose: 8000, });
                return false;
            } else {
                toast.success(response.message, { autoClose: 8000, });
                return response.data;
            }
        } catch (error) {
            errorHandler(error)
            return;
        }
    },

    delete: async ({ entity, id, options = {} }) => {
        try {
            const response = await axiosInstance.delete(entity + '/' + id);
            if (!response.success) {
                toast.error(response.message, { autoClose: 8000, });
                return false;
            } else {
                toast.success(response.message, { autoClose: 8000, });
                return true;
            }
        } catch (error) {
            console.log("server error", error);
            errorHandler(error)
            return;
        }
    },

    multiUpdate: async ({ entity, jsonData }) => {
        console.log('🚀 multiUpload Request 🚀 ~ file: request.js ~ line 42 ~ multiUpdate: ~ jsonData', jsonData);

        try {
            const response = await axiosInstance.post(entity + '/multi-update', jsonData);
            if (!response.success) {
                toast.error(response.message, { autoClose: 8000, });
                return false;
            } else {
                toast.success(response.message, { autoClose: 8000, });
                return true;
            }
        } catch (error) {
            errorHandler(error)
            return;
        }
    },


    multiDelete: async ({ entity, jsonData }) => {
        console.log('🚀 multiUpload Request 🚀 ~ file: request.js ~ line 42 ~ multiDelete: ~ jsonData', jsonData);

        try {
            const response = await axiosInstance.post(entity + '/multi-delete', jsonData);
            if (!response.success) {
                toast.error(response.message, { autoClose: 8000, });
                return false;
            } else {
                toast.success(response.message, { autoClose: 8000, });
                return true;
            }
        } catch (error) {
            errorHandler(error)
            return;
        }
    },

    read: async ({ entity, jsonData, options = {} }) => {
        try {
            const response = await axiosInstance.post(entity, jsonData);
            if (!response.success) {
                toast.error(response.message, { autoClose: 8000, });
                return false;
            } else if (response.data) {
                // dispatchNotification(response.message, 'success');
                return response.data;
            }
        } catch (error) {
            console.log("server error", error);
            errorHandler(error)
            return;
        }
    },
    upload: async ({ entity, jsonData }) => {
        try {
            const response = await axiosInstance.post(entity + '/upload', jsonData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            return response.data;
        } catch (error) {
            console.log("server error", error);
            return;
        }
    },
    post: async ({ entity, jsonData, options = {} }) => {
        try {
            const response = await axiosInstance.post(entity, jsonData);
            if (!response.success) {
                toast.error(response.message, { autoClose: 8000, });
                return false;
            } else {
                if (response.message) toast.success(response.message, { autoClose: 8000, });
                return response;
            }
        } catch (error) {
            console.log("server error", error);
            errorHandler(error)
            return;
        }
    },

    get: async ({ entity }) => {
        try {
            const response = await axiosInstance.get(entity);
            return response.data;
        } catch (error) {
            console.log("server error", error);
            return;
        }
    },
    fetch: async (url) => {
        try {
            const response = await axiosInstance.get(url);
            return response.data;
        } catch (error) {
            console.log("server error", error);
            return;
        }
    },
    patch: async ({ entity, jsonData }) => {
        try {
            const response = await axiosInstance.patch(entity, jsonData);
            return response.data;
        } catch (error) {
            console.log("server error", error);
            return;
        }
    },

    source: () => {
        const CancelToken = axiosInstance.CancelToken;
        const source = CancelToken.source();
        return source;
    },
};
export default request;
