import React, { useEffect } from 'react';
import Aos from "aos";
import { Link } from 'react-router-dom';

import breadcrumbsImg from '../../assets/images/breadcrumbs/1.jpg'

const Breadcrumb = (props) => {
	const { pageTitle, pageDescription } = props;

	useEffect(function () {
		Aos.init({ duration: 1500 });
	}, []);
	return (
		<div className="react-breadcrumbs">
			<div className="breadcrumbs-wrap">
				<img className="desktop w-full" src={breadcrumbsImg} alt="Breadcrumbs" />
				<img className="mobile w-full" src={breadcrumbsImg} alt="Breadcrumbs" />
				<div className="breadcrumbs-inner">
					<div className="container max-w-full">
						<div className="breadcrumbs-text" data-aos="zoom-in-down">
							<h1 className="breadcrumbs-title">{pageTitle ? pageTitle : 'Thunder Player'}</h1>
							<span className="breadcrumbs-description">{pageDescription ? pageDescription : ''}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Breadcrumb;




