import moment from "moment";

export const priceFormat = function (value) {
    const price = parseFloat(value || 0).toFixed(2);
    return price || 0;
}
export const dateFormat = function (date) {
    return moment(new Date(date)).format('DD/MM/YY')

}
export const dateTimeFormat = function (date) {
    return moment(new Date(date)).format('DD/MM/YY HH:mm A')

}