import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { useState } from "react";
import { Link } from 'react-router-dom';

import request from '../../service/request';

const ResellerForm = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [resellerInfo, setResellerInfo] = useState({
        name: "",
        email: "",
        phone: "",
        password: "",
        message: "",
    });

    const [errors, setErrors] = useState({
        name: "",
        email: "",
        phone: "",
        password: "",
    });

    const resetData = () => {
        setResellerInfo({
            name: "",
            email: "",
            phone: "",
            password: "",
            message: "",
        });

        setErrors({
            name: "",
            email: "",
            phone: "",
            password: "",
        });
    }
    const handleChange = (e) => {
        const { name, value } = e.target;

        setResellerInfo({
            ...resellerInfo,
            [name]: value,
        });

        // Validate fields on change
        if (name === "email") {
            validateEmail(value);
        } else if (name === "name") {
            validateName(value);
        } else if (name === "phone") {
            validatePhone(value);
        } else if (name === "password") {
            validatePassword(value);
        }
    };

    const handleSubmit = async () => {
        validateAllFields();

        const hasErrors = Object.values(errors).some(error => error !== "");
        // const hasValues = Object.values(resellerInfo).some(msg => msg === "");        
        const hasValues = Object.keys(resellerInfo).some(key => key !== 'message' && resellerInfo[key] === "");
        if (!hasErrors && !hasValues) {
            setIsLoading(true);
            const results = await request.post({ entity: "become-reseller", jsonData: resellerInfo });
            if (results) {
                resetData();
            }
            setIsLoading(false);
        }
    };

    const validateAllFields = () => {
        validateEmail(resellerInfo.email);
        validateName(resellerInfo.name);
        validatePhone(resellerInfo.phone);
        validatePassword(resellerInfo.password);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(email);
        setErrors((prevErrors) => ({
            ...prevErrors,
            email: isValid ? "" : "Invalid email address.",
        }));
    };

    const validateName = (name) => {
        const isValid = name.trim() !== "";
        setErrors((prevErrors) => ({
            ...prevErrors,
            name: isValid ? "" : "Name is required.",
        }));
    };

    const validatePhone = (phone) => {
        const phoneRegex = /^\d/; // Adjust the regex as per your requirement
        const isValid = phoneRegex.test(phone);
        setErrors((prevErrors) => ({
            ...prevErrors,
            phone: isValid ? "" : "Invalid phone number. Must be  digits.",
        }));
    };

    const validatePassword = (password) => {
        const isValid = password.length >= 6;
        setErrors((prevErrors) => ({
            ...prevErrors,
            password: isValid ? "" : "Password must be at least 6 characters long.",
        }));
    };

    const validateMessage = (message) => {
        const isValid = message.trim().length >= 10 && message.trim().length <= 200;
        setErrors((prevErrors) => ({
            ...prevErrors,
            message: isValid ? "" : "Message must be between 10 and 100 characters.",
        }));
    };

    return (
        <div className="react-blog-form">
            <h2 className="contact-title">Do you want to become a reseller?</h2>
            {/* <h5>If you have any idea or issues, we'd love to hear about it</h5> */}
            <div id="blog-form" className="blog-form">
                <div id="form-messages"></div>
                <div className="row">
                    <div className="col-lg-6">
                        <TextField
                            label="Name"
                            name="name"
                            value={resellerInfo.name}
                            onChange={handleChange}
                            error={!!errors.name}
                            helperText={errors.name}
                            fullWidth
                            required
                            margin="normal"
                        />
                    </div>

                    <div className="col-lg-6 pdl-5">
                        <TextField
                            label="Email"
                            name="email"
                            value={resellerInfo.email}
                            onChange={handleChange}
                            error={!!errors.email}
                            helperText={errors.email}
                            fullWidth
                            required
                            margin="normal"
                        />
                    </div>

                    <div className="col-lg-6">
                        <TextField
                            label="Phone"
                            name="phone"
                            value={resellerInfo.phone}
                            onChange={handleChange}
                            error={!!errors.phone}
                            helperText={errors.phone}
                            fullWidth
                            required
                            margin="normal"
                        />
                    </div>

                    <div className="col-lg-6 pdl-5">
                        <TextField
                            label="Password"
                            name="password"
                            value={resellerInfo.password}
                            onChange={handleChange}
                            error={!!errors.password}
                            helperText={errors.password}
                            fullWidth
                            required
                            margin="normal"
                        />
                    </div>
                    <div className="col-lg-12">
                        <TextField
                            label="Message"
                            name="message"
                            value={resellerInfo.message}
                            onChange={handleChange}
                            error={!!errors.message}
                            helperText={errors.message}
                            fullWidth
                            multiline
                            rows={5}
                            margin="normal"
                        />
                    </div>

                    <div className="col-lg-12 pt---20">
                        <p>
                            You already have a reseller account? <Link to="https://reseller.thunder-player.com" style={{ color: 'blue', textDecoration: 'underline !important' }}>Go to Reseller panel</Link>
                        </p>
                        {/* <Button variant="contained" color="primary" onClick={handleSubmit}>
                            Send Request
                        </Button> */}

                        <LoadingButton
                            onClick={handleSubmit}
                            endIcon={<SendIcon />}
                            loading={isLoading}
                            loadingPosition="end"
                            variant="contained"
                            size="large"
                        >
                            <span>Register</span>
                        </LoadingButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResellerForm;
