import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

// Image
import accordionImg from '../../assets/images/acc.png';
import shapeImg from '../../assets/images/banner/shape_01.png';

import request from "../../service/request";

const FaqSection = () => {
    const [items, setItems] = useState([]);
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const result = await request.list({ entity: "faq" });
            if (result) {
                setItems(result);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // const items = [
    //     {
    //         uuid: "a",
    //         heading: "Why is the app freezing on start?",
    //         content: "Because the app everytime when you start it, need to load the list from your source or providers server.",
    //     },
    //     {
    //         uuid: "b",
    //         heading: "Why is the app not working when the PLAYLIST worked on my computer?",
    //         content: "This can caused because of the ISP lock, if your computer is in a different Network. But when the network is same as your computer and it is not working it can be caused because of your TV`s supported format. Not every Smart-TV support all contents. Every TV model are totally different or may not support specific stream formats!<br />Below you can see the supported formarts from Smart TV`s",
    //     }
    // ]

    return (
        <div className="accordion__area p-relative pt---60 pb---40" >
            <div className="accordion__shape">
                <img className="accordion__shape-1" src={accordionImg} alt="shape png" />
                {/* <img className="accordion__shape-1a" src={shapeImg} alt="shape png" /> */}
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="accordion__wrapper">
                            <div className="accordion__wrapper-1">
                                <h6 className="wow animate__fadeInUp" data-wow-duration="0.3s">Find Your Answers</h6>
                                <h2 className="wow animate__fadeInUp" data-wow-duration="0.5s">Have any thought? Look here.</h2>
                                {/* <p className="wow animate__fadeInUp" data-wow-duration="0.7s">Completely plagiarize fully researched collaboration and  idea-sharing for covalent.</p> */}
                                <Link to="/contact" className="border-btns wow animate__fadeInUp" data-wow-duration="0.9s"> More Questions </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 wow animate__fadeInUp" data-wow-duration="0.6s">
                        <div className="accordion__wrapper1">
                            <Accordion className="accordion" allowZeroExpanded>
                                {items.map((item) => (
                                    <AccordionItem className="accordion-item" uuid={item._id}>
                                        <AccordionItemHeading className="accordion-button">
                                            <AccordionItemButton className="card-link">
                                                {item.question}
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="accordion-body px-8">
                                            {item.answer}
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FaqSection;