import React from "react";
import { Button } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import DirectionsIcon from '@mui/icons-material/Directions';
const BlogsMain = () => {


    return (
        <>
            <div className="w-full flex justify-center translate-y-[-50%] p-2">
                <Paper
                    component="form"
                    sx={{ p: '5px 10px', display: 'flex', alignItems: 'center' }}
                    className="sm:w-[50%] w-full"
                >
                    <SearchIcon />
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search an article"
                        inputProps={{ 'aria-label': 'search google maps' }}
                    />
                    <span className="text-gray-500">0 Result</span>
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <Button variant="contained" sx={{ p: '10px' }} aria-label="directions">
                        Search
                    </Button>
                </Paper>
            </div>
        </>
    )
}

export default BlogsMain;