import React from "react";
import { useSelector } from 'react-redux';
import Login from "../auth/Login"
import ItemList from "./ItemList";


import { selectAuth } from '../../redux/auth/selectors';
const PlaylistsMain = () => {
    const { isLoggedIn } = useSelector(selectAuth);


    return (
        isLoggedIn ?
            <>
                <ItemList />
            </>
            : <Login title="Login to manage your playlists"/>
    )
}

export default PlaylistsMain;