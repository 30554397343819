import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Button, FormControl, InputLabel, Typography, TextField } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';

import request from '../../service/request';
import { dateFormat, priceFormat } from "../../utils/helper";
import { LoadingButton } from "@mui/lab";
import { WindowSharp } from "@mui/icons-material";

const TransferSection = () => {

    const [macAddress, setMacAddress] = useState("");
    const [status, setStatus] = useState(false);
    const [expireDate, setExpireDate] = useState("");
    const [type, setType] = useState(1);
    const [yearPrice, setYearPrice] = useState(0);
    const [lifePrice, setLifePrice] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [targetMacAddress, setTargetMacAddress] = useState("");

    const [errorMacAddress, setErrorMacAddress] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {

                const result = await request.read({ entity: "config", jsonData: { ids: ["credit_price"] } });
                if (result) {
                    const prices = result?.credit_price;
                    const year_price = prices.find((item) => (item.type === 1))?.price;
                    const life_price = prices.find((item) => (item.type === 2))?.price;
                    setYearPrice(year_price);
                    setLifePrice(life_price);

                }
                const deviceInfo = await request.get({ entity: "profile" });
                if (deviceInfo) {
                    setMacAddress(deviceInfo.mac_address);
                    setStatus(deviceInfo.active_status);
                    setExpireDate(deviceInfo.expire_date);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);
    const submitHandler = async () => {
        if (errorMacAddress !== "" || targetMacAddress === "") return;
        try {
            setIsLoading(true);
            const response = await request.post({ entity: 'transfer', jsonData: { mac_address: targetMacAddress } });
            if (response) {
                window.location.href = "/activate";
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error creating payment intent:', error);
            setIsLoading(false);
        }
    }

    const formatMacAddress = (mac) => {
        return mac.replace(/[^a-fA-F0-9]/g, "").match(/.{1,2}/g)?.join(":").substring(0, 17) || "";
    };

    const handleChange = (e) => {
        const formattedValue = formatMacAddress(e.target.value);
        setTargetMacAddress(formattedValue);
        validateMacAddress(formattedValue);
    };

    const validateMacAddress = (mac) => {
        const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
        const isValid = macRegex.test(mac);
        setErrorMacAddress(isValid ? "" : "Invalid MAC address.");
    };
    return (
        <>
            <div className="container mx-auto px-4 sm:px-6 md:px-8 py-8 pt---50 pb---100">
                <div className="pt-2 pb-1 text-right">
                    <div>Mac address: <span>{macAddress}</span></div>
                    <div>Status: <span>{status ? "Active" : "Deactive"}</span></div>
                    <div>Expiration: <span>{dateFormat(expireDate)}</span></div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-6 gap-6">
                    <div>
                        <Button component={Link} to="/playlists" fullWidth variant="outlined" color="primary" className="mb-3">
                            PlayLists
                        </Button>
                        <Button component={Link} to="/activate" fullWidth variant="outlined" color="primary" className="mb-3">
                            Activate
                        </Button>
                        {
                            status &&

                            <Button component={Link} to="/transfer" fullWidth variant="contained" color="primary" className="mb-3">
                                Transfer
                            </Button>
                        }
                        <Button component={Link} to="/logout" fullWidth variant="contained" color="error" className="mb-3">
                            <LogoutIcon /> Logout
                        </Button>
                    </div>
                    <div className="col-span-5">
                        <div className="overflow-x-auto">
                            <div className="px-3 py-2 bg-blue-200 rounded-md mb-10">
                                <p>
                                    Disclaimer<br />
                                    We don't provide contents but pure media player. To use our player, you have to upload your own playlist.
                                    If you have no playlist, you can't use the player anymore so in this case no need to pay.
                                    Also we are not allowing activation for the users that don't have own playlist.<br />
                                    As each device has an unique Mac address, a payment is valid for a device only.
                                </p>
                            </div>
                            <div className="text-center grid grid-cols-4 pt-4">
                                <div className="col-start-2 col-span-2">
                                    <div>

                                        <FormControl color='primary' fullWidth >

                                            <TextField
                                                label="Mac Address"
                                                name="mac_address"
                                                value={targetMacAddress}
                                                onChange={handleChange}
                                                error={!!errorMacAddress}
                                                helperText={errorMacAddress}
                                                fullWidth
                                                required
                                                margin="normal"
                                                sx={{ marginBottom: "50px" }}
                                            />
                                        </FormControl>
                                        <LoadingButton size="large" fullWidth variant="contained" color="primary" className="mb-3" onClick={submitHandler} loading={isLoading} disabled={errorMacAddress !== "" || targetMacAddress === ""}>
                                            Transfer
                                        </LoadingButton>
                                    </div>
                                    <div>
                                        <p>
                                            After you make the payment, please reload or restart the application
                                            so that you can see your account has been activated.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TransferSection;