import { useEffect } from "react";
import { useParams } from 'react-router-dom';
import DownloadBreadcrumbs from '../../components/Breadcrumb/DownloadBreadcrumbs';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import ScrollToTop from '../../components/ScrollTop';
import DownloadMain from "./DownloadMain";


const Download = () => {

    const { type } = useParams();

    useEffect(() => {
        document.title = "Thunder Player | Download";
    }, []);
    return (
        <>
            <Header
                parentMenu='download'
            />

            <div className="react-wrapper">
                <div className="react-wrapper-inner">

                    <DownloadBreadcrumbs pageTitle="Download Thunder Player" pageDescription="" pageType={type} />

                    <DownloadMain type={type} />

                    <ScrollToTop />
                </div>
            </div>

            <Footer />

        </>
    );
}

export default Download;