export const storePersist = {
    set: (key, state) => {
        window.localStorage.setItem(key, JSON.stringify(state));
    },
    get: (key) => {
        try {
            const result = window.localStorage.getItem(key);
            if (result === null) return null;
            return JSON.parse(result);
        } catch (error) {
            // The data in localStorage is not a valid JSON string
            console.error(`Error parsing localStorage data for key "${key}": ${error}`);
            return null;
        }
    },
    remove: (key) => {
        window.localStorage.removeItem(key);
    },
    getAll: () => {
        return window.localStorage;
    },
    clear: () => {
        window.localStorage.clear();
    },
};

export default storePersist;
