import { useState, useEffect } from 'react';

import emailImg from '../../assets/images/icons/contacts/email.png'
import telegramImg from '../../assets/images/icons/contacts/telegram.png'
import whatsappImg from '../../assets/images/icons/contacts/whatsapp.png'

import request from '../../service/request'
const ContactInfo = (props) => {
    const { contactBoxClass } = props;

    const [contacts, setContacts] = useState({
        email: "",
        telegram: "",
        whatsapp: "",
        youtube: "",
        facebook: "",
        instagram: "",
        twitter: "",
    });
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await request.read({ entity: "config", jsonData: { ids: ["support_contacts"] } });
                if (result) {
                    setContacts(result?.support_contacts);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);


    return (
        <ul className="address-sec">
            <li>
                <em className="icon"><img src={emailImg} alt="image" width={50} /></em>
                <span className="text"><em>Email</em> <a href={"mail:" + contacts?.email} target="_blank">{contacts?.email}</a></span>
            </li>
            <li>
                <em className="icon"><img src={telegramImg} alt="image" width={50} /></em>
                <span className="text"><em>Telegram</em>  <a href={"https://t.me/" + contacts?.telegram} target="_blank">@{contacts?.telegram}</a></span>
            </li>
            <li>
                <em className="icon"><img src={whatsappImg} alt="image" width={50} /></em>
                <span className="text"><em>Whatsapp</em><a href={"https://api.whatsapp.com/send/?phone=" + contacts?.whatsapp} target="_blank">{contacts?.whatsapp}</a></span>
            </li>
        </ul>
    );

}

export default ContactInfo;