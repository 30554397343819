import { useEffect } from "react";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Breadcrumb from '../../components/Breadcrumb';
import ScrollToTop from '../../components/ScrollTop';
import BlogsMain from "./BlogsMain";


const Blogs = () => {

    useEffect(() => {
        document.title = "Thunder Player | Blogs";
    }, []);
    return (
        <>
            <Header
                parentMenu='page'
            />

            <div className="react-wrapper">
                <div className="react-wrapper-inner">

                    <Breadcrumb pageTitle="BLOGS" pageDescription="Latest updates and Hand-picked resources." />

                    <BlogsMain />

                    <ScrollToTop />
                </div>
            </div>

            <Footer />

        </>
    );
}

export default Blogs;