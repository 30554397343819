import React from "react";
import FaqSection from "./FaqSection";


const FaqMain = () => {


    return (
        <>
            <FaqSection />

        </>
    )
}

export default FaqMain;