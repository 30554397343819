import React, { useState, useEffect } from "react";
import { Route, Routes } from 'react-router-dom';
import { CssBaseline, GlobalStyles } from '@mui/material';

import Preloader from "../components/Preloader";
//Pages
import Home from '../pages/home';
import Download from '../pages/download';
import Playlists from '../pages/playlists';
import Activate from '../pages/activate';
import Transfer from '../pages/transfer';
import News from '../pages/news';
import Faq from '../pages/faq';
import Blogs from '../pages/blogs';
import BecomeReseller from '../pages/become_reseller';
import Contact from '../pages/contact';

import About from '../pages/about';
import TermsConditions from '../pages/terms_conditions';
import PrivacyPolicy from '../pages/privacy_policy';
import Logout from '../pages/auth/Logout'

import Error from '../pages/404';
import LoadTop from '../components/ScrollTop/LoadTop'

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        // Simulate data loading delay
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }, []);

    const globalStyles = {
        '::-webkit-scrollbar': {
            width: '12px',
        },
        '::-webkit-scrollbar-thumb': {
            backgroundColor: '#bfc8d8',
            borderRadius: '6px',
            border: '3px solid #fff',
        },
        '::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
    };
    return (
        <div className='App'>
            <CssBaseline />
            <GlobalStyles styles={globalStyles} />
            {isLoading ?
                <Preloader /> : ''
            }
            <>
                <LoadTop />
                <Routes>
                    <Route path="/" exact element={<Home />} />
                    <Route path="/playlists" element={<Playlists />} />
                    <Route path="/activate" element={<Activate pay_type="0" />} />
                    <Route path="/transfer" element={<Transfer />} />

                    <Route path="/payment-success" element={<Activate pay_type="1" />} />
                    <Route path="/payment-cancel" element={<Activate pay_type="2" />} />

                    <Route path="/news" element={<News />} />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/blogs" element={<Blogs />} />
                    <Route path="/become-reseller" element={<BecomeReseller />} />
                    <Route path="/contact" element={<Contact />} />

                    <Route path="/download/:type" element={<Download />} />

                    <Route path="/about-us" element={<About />} />
                    <Route path="/terms-and-conditions" element={<TermsConditions />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                    <Route path="/logout" element={<Logout />} />

                    <Route path='*' element={<Error />} />
                </Routes>
            </>
            <ToastContainer />
        </div>
    );
}

export default App;
