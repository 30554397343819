import React, { useEffect, useState } from "react";
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';
import Slider from "react-slick";


import sliderImg1 from "../../assets/images/slider/1.png";
import sliderImg2 from "../../assets/images/slider/2.png";
import sliderImg3 from "../../assets/images/slider/3.png";


import androidImg from '../../assets/images/icons/download/android.png';
import appleImg from '../../assets/images/icons/download/apple.png';
import lgImg from '../../assets/images/icons/download/lg.png';
import samsungImg from '../../assets/images/icons/download/samsung.png';
import windowsImg from '../../assets/images/icons/download/windows.png';

import request from '../../service/request';

const HomeSlider = () => {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    const sliderSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        margin: 0,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 1000,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    arrows: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                }
            }
        ]
    };
    const slideData = [
        { img: sliderImg1, alt: "Slider Image 1" },
        { img: sliderImg2, alt: "Slider Image 2" },
        { img: sliderImg3, alt: "Slider Image 3" },
    ];
    const [videoUrl, setVideoUrl] = useState("");
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await request.read({ entity: "config", jsonData: { ids: ["youtubu_video"] } });
                if (result && result?.youtubu_video) {
                    setVideoUrl(result?.youtubu_video);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);
    return (
        <>
            <div className="react-slider-part">
                {
                    videoUrl !== "" && <ModalVideo channel='youtube' isOpen={isOpen} videoId={videoUrl} onClose={() => { openModal(); }} />
                }
                <div className="home-sliders home2">
                    <Slider {...sliderSettings}>
                        {slideData.map((slide, index) => (
                            <div key={index} className="single-slide">
                                <div className="slider-img">
                                    <img className="desktop w-full" src={slide.img} style={{ height: '80vh' }} alt={slide.alt} />
                                    <img className="mobile w-full" src={slide.img} alt={slide.alt} />
                                </div>
                                <div className="container ">
                                    <div className="slider-content">
                                        <div className="content-part">
                                            {/* <span className="slider-pretitle wow animate__fadeInUp" data-wow-duration="1s">Great Quality Cocial life</span> */}
                                            <h2 className="slider-title wow animate__fadeInUp" data-wow-duration="1s">
                                                We Provide The Best<br />
                                                General Media Player
                                            </h2>
                                            <div className="slider-btn wow animate__fadeInUp" data-wow-duration="1.2s">
                                                <ul className='flex gap-3'>
                                                    <li>
                                                        <Link to="/download/samsung" className='download-item' title='Download Samsung'><em className="icon"><img src={samsungImg} alt="image" /></em></Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/download/lg" className='download-item' title='Download LG'><em className="icon"><img src={lgImg} alt="image" /></em></Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/download/windows" className='download-item' title='Download Windows'><em className="icon"><img src={windowsImg} alt="image" /></em></Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/download/android" className='download-item' title='Download Android'><em className="icon"><img src={androidImg} alt="image" /></em></Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/download/ios" className='download-item' title='Download iOS'><em className="icon"><img src={appleImg} alt="image" /></em></Link>
                                                    </li>
                                                </ul>


                                            </div>
                                        </div>
                                    </div>

                                    {
                                        videoUrl !== "" &&
                                        <div className="event__video-btn--play wow animate__fadeInUp" data-wow-duration="1.4s">
                                            <Link to="#" className="event__video-btn--play-btn custom-popup" onClick={() => { openModal(); }}>
                                                <i className="arrow_triangle-right"></i>
                                                <em>Watch Video <br />Intro</em>
                                            </Link>
                                        </div>
                                    }
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </>
    );
}

export default HomeSlider;