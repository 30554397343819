import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaGooglePlay } from "react-icons/fa6";
import { FaAppStoreIos } from "react-icons/fa6";
import { FaWindows } from "react-icons/fa6";
import { FaTv } from "react-icons/fa6";
import { FaDownload } from "react-icons/fa6";
import { FaAndroid } from "react-icons/fa6";


import windowsImg from '../../assets/images/icons/download/windows.png'
import appleImg from '../../assets/images/icons/download/apple.png'
import androidImg from '../../assets/images/icons/download/android.png'
import appletvImg from '../../assets/images/icons/download/apple-tv.png'
import lgImg from '../../assets/images/icons/download/lg.png'
import samsungImg from '../../assets/images/icons/download/samsung.png'
const MenuItems = (props) => {

    const { parentMenu } = props;
    const [download, setDownload] = useState(false)

    const location = useLocation();
    const openMobileMenu = menu => {
        if (menu === 'download') {
            setDownload(!download)
        }
    };
    return (
        <>
            <li className={location.pathname === '/' ? 'menu-active' : ''}>
                <Link to="/">Home</Link>
            </li>
            <li className={location.pathname === '/playlists' ? 'menu-active' : ''}>
                <Link to="/playlists">Manage Playlists</Link>
            </li>
            <li className={location.pathname === '/activate' ? 'menu-active' : ''}>
                <Link to="/activate">Activate</Link>
            </li>
            <li className={parentMenu === 'download' ? 'has-sub menu-active' : 'has-sub'}>
                <Link to="/download/samsung" className={download ? "hash menu-active" : "hash"} onClick={() => { openMobileMenu('download'); }}>
                    Download
                    <span className="arrow"></span>
                </Link>
                <ul className={download ? "sub-menu sub-menu-open" : "sub-menu"}>
                    <li className={location.pathname === '/download/samsung' ? 'menu-active' : ''}>
                        <Link to="/download/samsung" style={{ width: 200 }}><img src={samsungImg} alt="image" width={30} style={{ marginRight: 10 }} />Samsung TV</Link>
                    </li>
                    <li className={location.pathname === "/download/lg" ? "menu-active" : ""}>
                        <Link to="/download/lg" style={{ width: 200 }}><img src={lgImg} alt="image" width={30} style={{ marginRight: 10 }} />LG TV</Link>
                    </li>
                    <li className={location.pathname === "/download/windows" ? "menu-active" : ""}>
                        <Link to="/download/windows" style={{ width: 200 }}><img src={windowsImg} alt="image" width={30} style={{ marginRight: 10 }} />Windows</Link>
                    </li>
                    <li className={location.pathname === "/download/android" ? "menu-active" : ""}>
                        <Link to="/download/android" style={{ width: 200 }}><img src={androidImg} alt="image" width={30} style={{ marginRight: 10 }} />Android</Link>
                    </li>
                    <li className={location.pathname === "/download/ios" ? "menu-active" : ""}>
                        <Link to="/download/ios" style={{ width: 200 }}><img src={appleImg} alt="image" width={30} style={{ marginRight: 10 }} />iOS/tvOS</Link>
                    </li>
                </ul>
            </li>
            {/* <li className={location.pathname === '/news' ? 'menu-active' : ''}>
                <Link to="/news">News</Link>
            </li> */}
            {/* <li className={location.pathname === '/blogs' ? 'menu-active' : ''}>
                <Link to="/blogs">Blogs</Link>
            </li> */}
            <li className={location.pathname === '/become-reseller' ? 'menu-active' : ''}>
                <Link to="/become-reseller">Become Reseller</Link>
            </li>
            <li className={location.pathname === '/contact' ? 'menu-active' : ''}>
                <Link to="/contact">Contact</Link>
            </li>
            <li className={location.pathname === '/faq' ? 'menu-active' : ''}>
                <Link to="/faq">FAQ</Link>
            </li>
        </>
    );
}

export default MenuItems;