import { useState, useEffect } from "react";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Breadcrumb from '../../components/Breadcrumb';
import ScrollToTop from '../../components/ScrollTop';

import request from "../../service/request";
const PrivacyPolicy = () => {
    const [content, setContent] = useState("");

    useEffect(() => {
        document.title = "Thunder Player | Privacy Policy";
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const result = await request.read({ entity: "config", jsonData: { ids: ["privacy_policy"] } });
            if (result) {
                setContent(result?.privacy_policy);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    return (
        <>
            <Header
                parentMenu='page'
            />

            <div className="react-wrapper">
                <div className="react-wrapper-inner">

                    <Breadcrumb pageTitle="Privacy Policy" pageDescription="From: 2024-07-01" />

                    <div className="container fullwidth px-3 pt---80  pb---50"
                        dangerouslySetInnerHTML={{ __html: content }}>
                    </div>

                    <ScrollToTop />
                </div>
            </div>

            <Footer />

        </>
    );
}

export default PrivacyPolicy;