import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { Link } from "react-router-dom";
import aboutImg from '../../assets/images/about/ab.png';

import { Download } from "@mui/icons-material";
import request from '../../service/request';

import androidImg from '../../assets/images/icons/download/android.png';
import appleImg from '../../assets/images/icons/download/apple.png';
import lgImg from '../../assets/images/icons/download/lg.png';
import samsungImg from '../../assets/images/icons/download/samsung.png';
import windowsImg from '../../assets/images/icons/download/windows.png';
import { API_URL } from "../../utils/environment";

const DownloadMain = (props) => {

    const { type } = props;

    const [appLable, setAppLable] = useState("Android");
    const [appIcon, setAppIcon] = useState(windowsImg);

    const appList = [
        { type: "samsung", label: "Samsung TV", icon: <img src={samsungImg} alt="image" style={{ width: "30px", marginTop: "5px", marginBottom: "5px", marginRight: 20 }} /> },
        { type: "lg", label: "LG TV", icon: <img src={lgImg} alt="image" style={{ width: "30px", marginTop: "5px", marginBottom: "5px", marginRight: 20 }} /> },
        { type: "android", label: "Android", icon: <img src={androidImg} alt="image" style={{ width: "30px", marginRight: 20, padding: "5px 0px" }} /> },
        { type: "ios", label: "iOS/tvOS", icon: <img src={appleImg} alt="image" style={{ width: "30px", marginRight: 20, padding: "5px 0px" }} /> },
        { type: "windows", label: "Windows", icon: <img src={windowsImg} alt="image" style={{ width: "30px", marginRight: 20, padding: "5px 0px" }} /> },
    ]

    useEffect(() => {
        setAppLable(appList.find((item) => (item.type === type))?.label);
        setAppIcon(appList.find((item) => (item.type === type))?.icon);
        document.title = "Thunder Player | Download";
    }, [type]);

    const [appUrl, setAppUrl] = useState({
        "samsung": "",
        "lg": "",
        "windows": "",
        "android": "",
        "ios": "",
        "macos": ""
    });
    const [appFile, setAppFile] = useState({
        "samsung": "",
        "lg": "",
        "windows": "",
        "android": "",
        "ios": "",
        "macos": ""
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await request.read({ entity: "config", jsonData: { ids: ["latest_version"] } });
                if (result) {

                    const urls = {};
                    const files = {};
                    result.latest_version.forEach((obj) => {
                        urls[obj.app_type] = obj?.url;
                        files[obj.app_type] = obj?.file;
                    });
                    setAppUrl(urls);
                    setAppFile(files);

                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);
    return (
        <>
            <div className="download-tab__content mb---90 mt---90">
                <div className="google-tab">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                                    <img src={aboutImg} alt="About" />
                                </div>
                            </div>
                            <div className="col-lg-6 pl---50 pt---30">
                                <div className="about__content">
                                    <h3 className="about__title wow animate__fadeInUp text-3xl mb-4" data-wow-duration="0.3s">The best Thunder Player for {appLable}</h3>
                                    <ul className="app-description">
                                        <li className="flex items-center"><FaCheck className="check" />Register for free</li>
                                        <li className="flex items-center"><FaCheck className="check" />Add your playlists</li>
                                        <li className="flex items-center"><FaCheck className="check" />Activate your account</li>
                                        <li className="flex items-center"><FaCheck className="check" />Fast Streaming</li>
                                    </ul>
                                    <div className="mt---50 grid grid-cols-1 sm:grid-cols-2 gap-3">
                                        {
                                            (type === "android") ?
                                                <>
                                                    <a target="_blank" href={appUrl[type] ?? "#"} className="download-button flex items-center justify-center space-x-2">
                                                        {appIcon}Install from Playstore
                                                    </a>
                                                    {
                                                        appFile[type] &&
                                                        <a target="_blank" href={API_URL + "download/android"} className="download-button flex items-center justify-center space-x-2" style={{ backgroundColor: 'blue' }}>
                                                            <Download style={{ marginRight: 20 }} />Download apk File
                                                        </a>
                                                    }
                                                </> :
                                                (type === "windows") ?
                                                    <>
                                                        <a target="_blank" href={appUrl[type] ?? "#"} className="download-button flex items-center justify-center space-x-2">
                                                            {appIcon}Install from Mircrosoft Store
                                                        </a>
                                                        {
                                                            appFile[type] &&
                                                            <a target="_blank" href={API_URL + "download/windows"} className="download-button flex items-center justify-center space-x-2" style={{ backgroundColor: 'blue' }}>
                                                                <Download style={{ marginRight: 20 }} />Download exe File
                                                            </a>
                                                        }
                                                    </> :
                                                    (type === "ios") ?
                                                        <a target="_blank" href={appUrl[type] ?? "#"} className="download-button flex items-center justify-center space-x-2">
                                                            {appIcon} Install from AppStore
                                                        </a>
                                                        :
                                                        (type === "samsung") ?
                                                            <a target="_blank" href={appUrl[type] ?? "#"} className="download-button flex items-center justify-center space-x-2">
                                                                {appIcon} Install from Samsung Store
                                                            </a>
                                                            :
                                                            <a target="_blank" href={appUrl[type] ?? "#"} className="download-button flex items-center justify-center space-x-2">
                                                                {appIcon} Install from LG Store
                                                            </a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DownloadMain;