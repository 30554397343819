import React from "react";
import { useSelector } from 'react-redux';
import Login from "../auth/Login"
import TransferSection from "./TransferSection";
import { selectAuth } from '../../redux/auth/selectors';

const TransferMain = () => {
    const { isLoggedIn } = useSelector(selectAuth);


    return (
        isLoggedIn ?
            <TransferSection />
            : <Login />
    )
}

export default TransferMain;