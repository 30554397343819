import { useEffect } from "react";
import Breadcrumb from '../../components/Breadcrumb';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import ScrollToTop from '../../components/ScrollTop';
import AboutMain from './AboutMain';


const About = () => {

    useEffect(() => {
        document.title = "Thunder Player | About Us";
    }, []);
    return (
        <>
            <Header
                parentMenu='page'
            />

            <div className="react-wrapper">
                <div className="react-wrapper-inner">

                    <Breadcrumb pageTitle="About Us" pageDescription="What is Thunder Player?" />

                    <AboutMain />

                    <ScrollToTop />
                </div>
            </div>

            <Footer />

        </>
    );
}

export default About;