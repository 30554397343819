import { useEffect } from "react";
import Breadcrumb from '../../components/Breadcrumb';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import ScrollToTop from '../../components/ScrollTop';
import ContactMain from './ContactMain';

// Image

const Contact = () => {

    useEffect(() => {
        document.title = "Thunder Player | Contact";
    }, []);
    return (
        <>
            <Header
                parentMenu='contact'
                menuCategoryEnable='enable'
            />

            <div className="react-wrapper">
                <div className="react-wrapper-inner">
                    <Breadcrumb
                        pageTitle="CONTACT US"
                        pageDescription="Thunder Player is a fantastic IPTV application good functionalities."
                    />

                    <ContactMain />

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <Footer />

        </>
    );
}


export default Contact;

