import { useEffect } from "react";
import Breadcrumb from '../../components/Breadcrumb';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import ScrollToTop from '../../components/ScrollTop';
import TransferMain from "./TransferMain";
const Activate = () => {

    useEffect(() => {
        document.title = "Thunder Player | Transfer Activation";
    }, []);


    return (
        <>
            <Header
                parentMenu='page'
            />

            <div className="react-wrapper">
                <div className="react-wrapper-inner">

                    <Breadcrumb pageTitle="Transfer Activation" pageDescription="" />
                    <TransferMain />
                    <ScrollToTop />
                </div>
            </div>

            <Footer />

        </>
    );
}

export default Activate;