import React from 'react';
import { createRoot } from "react-dom/client";
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import "aos/dist/aos.css";

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './assets/scss/main.scss';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</React.StrictMode>
);

reportWebVitals();
