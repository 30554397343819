import React, { useEffect } from 'react';
import Aos from "aos";
import { Link } from 'react-router-dom';
import breadcrumbsImg from '../../assets/images/breadcrumbs/1.jpg'


import windowsImg from '../../assets/images/icons/download/windows.png'
import appleImg from '../../assets/images/icons/download/apple.png'
import androidImg from '../../assets/images/icons/download/android.png'
import appletvImg from '../../assets/images/icons/download/apple-tv.png'
import lgImg from '../../assets/images/icons/download/lg.png'
import samsungImg from '../../assets/images/icons/download/samsung.png'

const DownloadBreadcrumbs = (props) => {

    useEffect(function () {
        Aos.init({ duration: 1500 });
    }, []);
    const { pageTitle, pageDescription, pageType } = props;
    return (
        <div className="react-breadcrumbs">
            <div className="breadcrumbs-wrap">
                <img className="desktop w-full" src={breadcrumbsImg} alt="Breadcrumbs" />
                <img className="mobile w-full" src={breadcrumbsImg} alt="Breadcrumbs" style={{ height: "300px" }} />
                <div className="breadcrumbs-inner">
                    <div className="container max-w-full">
                        <div className="breadcrumbs-text" data-aos="zoom-in-down">
                            <h1 className="breadcrumbs-title">{pageTitle ? pageTitle : 'Thunder Player'}</h1>
                            <span className="breadcrumbs-description">{pageDescription ? pageDescription : ''}</span>
                            <ul className="download-tab flex flex-wrap justify-center w-full mt-3 pb-2" >

                                <li>
                                    <Link to="/download/samsung" className={`download-item ${pageType === "android" ? "download-item__active" : ""}`}><em className="icon"><img src={samsungImg} alt="image" /></em> Samsung</Link>
                                </li>
                                <li>
                                    <Link to="/download/lg" className={`download-item ${pageType === "android" ? "download-item__active" : ""}`}><em className="icon"><img src={lgImg} alt="image" /></em>LG TV</Link>
                                </li>
                                <li>
                                    <Link to="/download/windows" className={`download-item ${pageType === "android" ? "download-item__active" : ""}`}><em className="icon"><img src={windowsImg} alt="image" /></em>Windows</Link>
                                </li>
                                <li>
                                    <Link to="/download/android" className={`download-item ${pageType === "android" ? "download-item__active" : ""}`}><em className="icon"><img src={androidImg} alt="image" /></em>Android</Link>
                                </li><li>
                                    <Link to="/download/ios" className={`download-item ${pageType === "android" ? "download-item__active" : ""}`}><em className="icon"><img src={appleImg} alt="image" /></em>iOS / tvOS</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default DownloadBreadcrumbs;




