import { useEffect, useState } from "react";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Breadcrumb from '../../components/Breadcrumb';
import ScrollToTop from '../../components/ScrollTop';

import request from "../../service/request";
const TermsConditions = () => {
    const [content, setContent] = useState("");
    useEffect(() => {
        document.title = "Thunder Player | Terms and Conditions";
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const result = await request.read({ entity: "config", jsonData: { ids: ["terms_conditions"] } });
            if (result) {
                setContent(result?.terms_conditions);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    return (
        <>
            <Header
                parentMenu='page'
            />

            <div className="react-wrapper">
                <div className="react-wrapper-inner">

                    <Breadcrumb pageTitle="Terms and Conditions" pageDescription="Explore the Guidelines: Your Roadmap to Understanding and Adhering to Our Terms and Conditions." />

                    <div className="container fullwidth px-3 pt---80  pb---50"
                        dangerouslySetInnerHTML={{ __html: content }}>
                    </div>

                    <ScrollToTop />
                </div>
            </div>

            <Footer />

        </>
    );
}

export default TermsConditions;