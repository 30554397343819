import React from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const NewsMain = () => {


    return (
        <>
            <div className="w-full flex justify-center pt-5 pb-5 ">
                <div className="sm:w-[50%] w-full m-2 news-container">
                    <div className="news-item bg-white rounded-lg shadow-[0px_2px_4px_-1px_rgba(0,0,0,0.2),0px_4px_5px_0px_rgba(0,0,0,0.14),0px_1px_10px_0px_rgba(0,0,0,0.12)] p-3 mb-3" data-aos="fade-up">
                        <div className="w-full flex">
                            <div className="bg-gray-200 rounded-full text-sm p-1 flex items-center">
                                <AccountCircleIcon /> VivoPlayer
                            </div>
                            <div className="bg-gray-200 rounded-full text-sm p-1 flex items-center ml-4">
                                <CalendarMonthIcon /> 5/29/2024
                            </div>
                        </div>
                        <div className="w-full pt-3 ">
                            <h3 className="text-xl pb-2 border-b-2 border-gray-300">Multi-Languages were added to website.</h3>
                            <p className="pt-2">
                                We have added multi-languages by using google translator to the website.
                            </p>
                        </div>
                    </div>
                    <div className="news-item bg-white rounded-lg shadow-[0px_2px_4px_-1px_rgba(0,0,0,0.2),0px_4px_5px_0px_rgba(0,0,0,0.14),0px_1px_10px_0px_rgba(0,0,0,0.12)] p-3 mb-3" data-aos="fade-up">
                        <div className="w-full flex">
                            <div className="bg-gray-200 rounded-full text-sm p-1 flex items-center">
                                <AccountCircleIcon /> VivoPlayer
                            </div>
                            <div className="bg-gray-200 rounded-full text-sm p-1 flex items-center ml-4">
                                <CalendarMonthIcon /> 5/29/2024
                            </div>
                        </div>
                        <div className="w-full pt-3 ">
                            <h3 className="text-xl pb-2 border-b-2 border-gray-300">Multi-Languages were added to website.</h3>
                            <p className="pt-2">
                                We have added multi-languages by using google translator to the website.
                            </p>
                        </div>
                    </div>
                    <div className="news-item bg-white rounded-lg shadow-[0px_2px_4px_-1px_rgba(0,0,0,0.2),0px_4px_5px_0px_rgba(0,0,0,0.14),0px_1px_10px_0px_rgba(0,0,0,0.12)] p-3 mb-3" data-aos="fade-up">
                        <div className="w-full flex">
                            <div className="bg-gray-200 rounded-full text-sm p-1 flex items-center">
                                <AccountCircleIcon /> VivoPlayer
                            </div>
                            <div className="bg-gray-200 rounded-full text-sm p-1 flex items-center ml-4">
                                <CalendarMonthIcon /> 5/29/2024
                            </div>
                        </div>
                        <div className="w-full pt-3 ">
                            <h3 className="text-xl pb-2 border-b-2 border-gray-300">Multi-Languages were added to website.</h3>
                            <p className="pt-2">
                                We have added multi-languages by using google translator to the website.
                            </p>
                        </div>
                    </div>
                    <div className="news-item bg-white rounded-lg shadow-[0px_2px_4px_-1px_rgba(0,0,0,0.2),0px_4px_5px_0px_rgba(0,0,0,0.14),0px_1px_10px_0px_rgba(0,0,0,0.12)] p-3 mb-3" data-aos="fade-up">
                        <div className="w-full flex">
                            <div className="bg-gray-200 rounded-full text-sm p-1 flex items-center">
                                <AccountCircleIcon /> VivoPlayer
                            </div>
                            <div className="bg-gray-200 rounded-full text-sm p-1 flex items-center ml-4">
                                <CalendarMonthIcon /> 5/29/2024
                            </div>
                        </div>
                        <div className="w-full pt-3 ">
                            <h3 className="text-xl pb-2 border-b-2 border-gray-300">Multi-Languages were added to website.</h3>
                            <p className="pt-2">
                                We have added multi-languages by using google translator to the website.
                            </p>
                        </div>
                    </div>
                    <div className="news-item bg-white rounded-lg shadow-[0px_2px_4px_-1px_rgba(0,0,0,0.2),0px_4px_5px_0px_rgba(0,0,0,0.14),0px_1px_10px_0px_rgba(0,0,0,0.12)] p-3 mb-3" data-aos="fade-up">
                        <div className="w-full flex">
                            <div className="bg-gray-200 rounded-full text-sm p-1 flex items-center">
                                <AccountCircleIcon /> VivoPlayer
                            </div>
                            <div className="bg-gray-200 rounded-full text-sm p-1 flex items-center ml-4">
                                <CalendarMonthIcon /> 5/29/2024
                            </div>
                        </div>
                        <div className="w-full pt-3 ">
                            <h3 className="text-xl pb-2 border-b-2 border-gray-300">Multi-Languages were added to website.</h3>
                            <p className="pt-2">
                                We have added multi-languages by using google translator to the website.
                            </p>
                        </div>
                    </div>
                    <div className="news-item bg-white rounded-lg shadow-[0px_2px_4px_-1px_rgba(0,0,0,0.2),0px_4px_5px_0px_rgba(0,0,0,0.14),0px_1px_10px_0px_rgba(0,0,0,0.12)] p-3 mb-3" data-aos="fade-up">
                        <div className="w-full flex">
                            <div className="bg-gray-200 rounded-full text-sm p-1 flex items-center">
                                <AccountCircleIcon /> VivoPlayer
                            </div>
                            <div className="bg-gray-200 rounded-full text-sm p-1 flex items-center ml-4">
                                <CalendarMonthIcon /> 5/29/2024
                            </div>
                        </div>
                        <div className="w-full pt-3 ">
                            <h3 className="text-xl pb-2 border-b-2 border-gray-300">Multi-Languages were added to website.</h3>
                            <p className="pt-2">
                                We have added multi-languages by using google translator to the website.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewsMain;