import React from "react";

import AboutPart from './AboutSection'

const AboutMain = () => {


    return (
        <>
            <AboutPart />

        </>
    )
}

export default AboutMain;