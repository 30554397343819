import React from 'react';
import { Link } from 'react-router-dom';

import aboutImg from '../../assets/images/about/ab.png';

const AboutPart = () => {

    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            <img src={aboutImg} alt="About" />
                            {/* <img className="react__shape__ab" src={shapeImg} alt="Shape Image" /> */}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">Welcome to <br /> <em>Thunder Player</em></h2>
                            <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s"> a trailblazer in the world of TV streaming..</p>
                            <p className="about__paragraph2 wow animate__fadeInUp" data-wow-duration="0.7s"> Have questions?  <Link to="/faq"> Get Free Guide </Link></p>
                            <p className="wow animate__fadeInUp" data-wow-duration="0.9s">
                                At Thunder Player, we are dedicated to delivering a streaming experience that goes beyond the ordinary.
                                <br />
                                Our mission is to redefine the way you consume entertainment, offering a platform that seamlessly integrates convenience, quality, and variety.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <p><br /></p>

                        <p>Discover a world of possibilities with our state-of-the-art Thunder Player app. Designed with user-friendliness in mind, our app allows you to load your IPTV playlists swiftly, giving you instant access to an extensive range of channels. Whether you're a movie buff, sports enthusiast, news junkie, or just looking for some quality entertainment, Thunder Player has you covered.</p>

                        <p>What sets us apart is our commitment to accessibility. </p>

                        <p>The Thunder Player app is compatible with a diverse array of platforms, including Samsung, LG, Windows, Android, and iOS/macOS. This means you can enjoy your favorite channels on your preferred device, ensuring flexibility and convenience for your entertainment needs.</p>

                        <p>Activate the Thunder Player app today and immerse yourself in an unparalleled streaming experience. Download it now and witness a new era of entertainment at your fingertips.</p>

                        <p>Thank you for choosing Thunder Player as your trusted streaming companion. We are passionate about reshaping your TV viewing experience, and we look forward to being an integral part of your entertainment journey.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPart;