import { useEffect } from "react";
import { toast } from "react-toastify";
import Breadcrumb from '../../components/Breadcrumb';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import ScrollToTop from '../../components/ScrollTop';
import ActivateMain from "./ActivateMain";
const Activate = (props) => {
    const { pay_type } = props;

    useEffect(() => {
        console.log("-payment", pay_type);
        document.title = "Thunder Player | Activate Your Device";
        if (pay_type === "1") {
            toast.success("Payment was processed successfuly.");
        } else if (pay_type === "2") {
            toast.error("Payment was not processed. Please check your stripe account.");
        }
    }, [pay_type]);


    return (
        <>
            <Header
                parentMenu='page'
            />

            <div className="react-wrapper">
                <div className="react-wrapper-inner">

                    <Breadcrumb pageTitle="ACTIVATE YOUR DEVICE" pageDescription="" />
                    <ActivateMain />
                    <ScrollToTop />
                </div>
            </div>

            <Footer />

        </>
    );
}

export default Activate;