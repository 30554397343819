import { useState } from "react";
import emailjs from 'emailjs-com';
import { TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import request from '../../service/request';

const ContactForm = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState({
        name: "",
        email: "",
        phone: "",
        mac_address: "",
        subject: "",
        content: "",
    });

    const [errors, setErrors] = useState({
        name: "",
        email: "",
        phone: "",
        mac_address: "",
        subject: "",
        content: "",
    });

    const resetData = () => {
        setMessage({
            name: "",
            email: "",
            phone: "",
            mac_address: "",
            subject: "",
            content: "",
        });

        setErrors({
            name: "",
            email: "",
            phone: "",
            mac_address: "",
            subject: "",
            content: "",
        });
    }

    const formatMacAddress = (mac) => {
        return mac.replace(/[^a-fA-F0-9]/g, "").match(/.{1,2}/g)?.join(":").substring(0, 17) || "";
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        const formattedValue = name === "mac_address" ? formatMacAddress(value) : value;

        setMessage({
            ...message,
            [name]: formattedValue,
        });

        // Validate fields on change
        if (name === "email") {
            validateEmail(value);
        } else if (name === "name") {
            validateName(value);
        } else if (name === "phone") {
            validatePhone(value);
        } else if (name === "mac_address") {
            validateMacAddress(formattedValue);
        } else if (name === "subject") {
            validateSubject(value);
        } else if (name === "content") {
            validateContent(value);
        }
    };

    const handleSubmit = async () => {
        validateAllFields();

        const hasErrors = Object.values(errors).some(error => error !== "");
        // const hasValues = Object.values(message).some(msg => msg === "");
        const hasValues = Object.keys(message).some(key => key !== 'phone' && key !== 'mac_address' && message[key] === "");
        if (!hasErrors && !hasValues) {
            setIsLoading(true);
            const results = await request.post({ entity: "messages", jsonData: message });
            if (results) {
                resetData();
            }
            setIsLoading(false);
        }
    };

    const validateAllFields = () => {
        validateEmail(message.email);
        validateName(message.name);
        validatePhone(message.phone);
        validateMacAddress(message.mac_address);
        validateSubject(message.subject);
        validateContent(message.content);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(email);
        setErrors((prevErrors) => ({
            ...prevErrors,
            email: isValid ? "" : "Invalid email address.",
        }));
    };

    const validateName = (name) => {
        const isValid = name.trim() !== "";
        setErrors((prevErrors) => ({
            ...prevErrors,
            name: isValid ? "" : "Name is required.",
        }));
    };

    const validatePhone = (phone) => {
        const phoneRegex = /^\d/; // Adjust the regex as per your requirement
        const isValid = phoneRegex.test(phone);
        setErrors((prevErrors) => ({
            ...prevErrors,
            phone: phone === "" || isValid ? "" : "Invalid phone number. Must be  digits.",
        }));
    };

    const validateMacAddress = (mac) => {
        const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
        const isValid = macRegex.test(mac);
        setErrors((prevErrors) => ({
            ...prevErrors,
            mac_address: mac === "" || isValid ? "" : "Invalid MAC address.",
        }));
    };

    const validateSubject = (subject) => {
        const isValid = subject.trim() !== "";
        setErrors((prevErrors) => ({
            ...prevErrors,
            subject: isValid ? "" : "Subject is required.",
        }));
    };

    const validateContent = (content) => {
        const isValid = content.trim().length >= 10 && content.trim().length <= 200;
        setErrors((prevErrors) => ({
            ...prevErrors,
            content: isValid ? "" : "Content must be between 10 and 100 characters.",
        }));
    };

    return (
        <div className="react-blog-form">
            <h2 className="contact-title">Drop us a message for any query</h2>
            <h5>If you have any idea or issues, we'd love to hear about it</h5>
            <div id="blog-form" className="blog-form">
                <div id="form-messages"></div>
                <div className="row">
                    <div className="col-lg-6">
                        <TextField
                            label="Name"
                            name="name"
                            value={message.name}
                            onChange={handleChange}
                            error={!!errors.name}
                            helperText={errors.name}
                            fullWidth
                            required
                            margin="normal"
                        />
                    </div>

                    <div className="col-lg-6 pdl-5">
                        <TextField
                            label="Email"
                            name="email"
                            value={message.email}
                            onChange={handleChange}
                            error={!!errors.email}
                            helperText={errors.email}
                            fullWidth
                            required
                            margin="normal"
                        />
                    </div>

                    <div className="col-lg-6">
                        <TextField
                            label="Phone"
                            name="phone"
                            value={message.phone}
                            onChange={handleChange}
                            error={!!errors.phone}
                            helperText={errors.phone}
                            fullWidth
                            margin="normal"
                        />
                    </div>

                    <div className="col-lg-6 pdl-5">
                        <TextField
                            label="Mac Address"
                            name="mac_address"
                            value={message.mac_address}
                            onChange={handleChange}
                            error={!!errors.mac_address}
                            helperText={errors.mac_address}
                            fullWidth
                            margin="normal"
                        />
                    </div>

                    <div className="col-lg-12">
                        <TextField
                            label="Subject"
                            name="subject"
                            value={message.subject}
                            onChange={handleChange}
                            error={!!errors.subject}
                            helperText={errors.subject}
                            fullWidth
                            required
                            margin="normal"
                        />
                    </div>
                    <div className="col-lg-12">
                        <TextField
                            label="Content"
                            name="content"
                            value={message.content}
                            onChange={handleChange}
                            error={!!errors.content}
                            helperText={errors.content}
                            fullWidth
                            required
                            multiline
                            rows={5}
                            margin="normal"
                        />
                    </div>

                    <div className="col-lg-12  pt---20">
                        {/* <Button variant="contained" color="primary" onClick={handleSubmit}>
                            Send Message
                        </Button> */}
                        <LoadingButton
                            onClick={handleSubmit}
                            endIcon={<SendIcon />}
                            loading={isLoading}
                            loadingPosition="end"
                            variant="contained"
                            size="large"
                        >
                            <span>Send Message</span>
                        </LoadingButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactForm;
