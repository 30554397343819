import React from 'react';
import HomeSlider from './SliderSection';
import ScrollToTop from '../../components/ScrollTop';
import Download from '../../components/Download';
import HelpVideo from '../../components/HelpVideo';
import Feature from '../../components/Feature';

const HomeMain = () => {
	return (
		<>
			<div className="react-wrapper">
				<div className="react-wrapper-inner">
					<HomeSlider />
					{/* <Download /> */}
					{/* <HelpVideo /> */}
					<Feature />
					<ScrollToTop
						scrollClassName="home react__up___scroll"
					/>
				</div>
			</div>

		</>
	);
}

export default HomeMain;